<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Preloading -->
  <div class="container-fluid py-4 text-center" v-if="!show">
    <Preloader />
  </div>
  <div class="container-fluid mt-4">
    
    <div class="row">
      <div class="col-lg-4 col-md-6 mb-4">
                <place-holder-card
                  :title="{ text: 'Create a New Session', variant: 'h5' }"
                  url="/session/new"
                  color="success"
                />
      </div>
    </div>
    <hr class="hr">
    <ClientsSessions :sessions="therapist.sessions" v-if="show"/>

  </div>
</template>

<script>
import PlaceHolderCard from "@/examples/Cards/PlaceHolderCard.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import ClientsSessions from "./components/ClientsSessions.vue";
import axios from 'axios';
import {mapGetters } from "vuex";
import Preloader from "./components/Preloader.vue";

export default {
  name: "Sessions",
  data() {
    return {
      iconBackground: "bg-gradient-success",
      therapist: [],
      show: false,
    }
  },
  components: {
    PlaceHolderCard,
    ClientsSessions,
    Preloader
  },
  methods: {
    async fetchData(target) {
        let nextPage = target; // Replace this with your API endpoint
        let variable = [];

        try {
          while (nextPage) {
            const response = await axios.get(nextPage);
            const data = response.data;

            // Append results from current page to allInstances
            variable = variable.concat(data.results);

            // Update nextPage with the URL of the next page, if it exists
            nextPage = data.next;
          }

          // At this point, allInstances will contain all fetched instances
          return variable;
          
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      },
  },
async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
        this.therapist = therapist[0];
      });
      this.show= true;
},
mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
beforeUnmount() {
  this.$store.state.isAbsolute = false;
},
computed: {
  ...mapGetters(['getToken', 'getRToken', 'getUser']),
}
};
</script>
