<template>
    <div class="card mb-4">
      <div class="card-header pb-0" style="margin-left: 1rem;">
        <h5 class="mb-3">Your Clients</h5>
        <p class="text-sm"></p>
        <div class="row">
            <input
            id="search"
            type="text"
            class="form-control"
            style="width: auto; height: fit-content;"
            name="search"
            v-model="searchQuery"
            placeholder="search clients ..."
            
          />
          <div class="col-md-3">
              <select v-model="showPerPage" class="form-select form-select-border-radius-3">
                <option active selected value=10>10 Clients Per Page</option>
                <option value=50> 50 Clients Per Page </option>
              </select>
          </div>
          <div class="col-lg-3">
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="changePage(currentPage - 1)" :disabled="currentPage === 1"><i class="fa fa-arrow-left" style="color: green;"></i></button>
                </li>
                <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                  <button class="page-link" @click="changePage(pageNumber)">{{ pageNumber }}</button>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                  <button class="page-link" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"><i class="fa fa-arrow-right" style="color: green;"></i></button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2" style="margin-left: 1rem;">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Client
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  # of sessions
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Journey
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="client in paginatedClients" :key="client">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <a :href="'/clients/'+ client.id">
                      <soft-avatar
                        :img="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed=' + client.first_name"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="{{ client.first_name + ' ' + client.last_name }}"
                      /></a>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <a :href="'/clients/'+ client.id"><h6 class="mb-0 text-sm">{{ client.first_name + ' ' + client.last_name }}</h6></a>
                      <p class="text-xs text-secondary mb-0">
                        {{client.email}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ client.sessions.length }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <a :href="client.journey ? '/journeys/'+ client.journey_data.id : 'javascript::'">
                  <soft-badge :color="client.journey ? 'info' : 'secondary'" variant="gradient" size="sm"
                    >{{ client.journey ? "Assigned" :
                    "not assigned" }}</soft-badge
                  ></a>
                </td>
                <td class="align-middle text-center text-sm btn-">

                  <soft-badge :color="client.status === 'Completed' ? 'light': client.status === 'Ongoing' ? 'warning' : 'success'" variant="gradient" size="sm"
                    >{{ client.status ? client.status : "pending" }}</soft-badge
                  >
                </td>
                <td class="align-middle">
                  <i class="fa fa-user text-success" aria-hidden="true"></i>
                  <a
                    :href="'/clients/'+ client.id"
                    class="text-secondary font-weight-bold text-xs"
                    > Client Zone
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="inline m-4"> 
        <a
            class="btn bg-gradient-info m-1"
            href="/sessions"
            type="button"
            ><i class="fa fa-arrow-left"></i> Go to all sessions</a
          >
      </div>
    </div>
</template>
<script>

import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import axios from 'axios';

export default {
  name: "clients-table",
  data() {
    return {
      searchQuery: "",
      showPerPage: 10, // Default number of clients to show per page
      currentPage: 1, // Current page number
      selectedClient: null,
      clients: [],
      therapist: [],
    };
  },
  components: {
    SoftAvatar,
    SoftBadge
  },
  async created() {
    await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist;
      this.clients= therapist.clients;
    })
  },
  methods: {
    async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable[0];
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
    // Change the current page
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    handleCheckboxChange(client) {
      if (this.selectedClient === client) {
        this.selectedClient = null; // Unselect the client if it's already selected
      } else {
        this.selectedClient = null; // Select the clicked client
        this.selectedClient = client; // Select the clicked client
      }
    },
},
  computed: {
    filteredClients() {
      if (this.searchQuery=="") {
        return this.clients;
      } else {
        const query = this.searchQuery.toLowerCase();
        return this.clients.filter(client => {
          return (
            client.first_name.toLowerCase().includes(query) ||
            client.last_name.toLowerCase().includes(query) ||
            client.email.toLowerCase().includes(query)
            // You can add more properties for filtering if needed
          );
        });
      }
    },
    // Calculate total number of pages based on showPerPage and filtered clients
    totalPages() {
      return Math.ceil(this.filteredClients.length / this.showPerPage);
    },
    // Paginate the clients based on current page and showPerPage
    paginatedClients() {
      const startIndex = (this.currentPage - 1) * this.showPerPage;
      const endIndex = startIndex + this.showPerPage;
      return this.filteredClients.slice(startIndex, endIndex);
    },
  },
  watch: {
      // Watch for changes in showPerPage and reset currentPage to 1
      showPerPage: function(newShowPerPage, oldShowPerPage) {
        if (newShowPerPage !== oldShowPerPage) {
          this.currentPage = 1;
        }
    }
  },
  
};
</script>