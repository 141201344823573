<template>
  <!-- Header  -->
  <div class="container-fluid">
    <!-- background picture  -->
    <div
      class="mt-4 page-header min-height-100 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <!-- Preloading -->
  <div class="container-fluid py-4 text-center" v-if="!show">
    <Spinner />
  </div>
  <!-- Main  -->
  <div class="container-fluid py-4" v-if="show">
    <!-- Client info card -->
    <div class="row mt-3" v-if="currentSession.client">
      <div class="col-12">
        <div class=" card mb-4">
          <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Client
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      title of session
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      # of session
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Journey
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <router-link :to="{ name: 'Client Profile', params: { id: currentSession.client_data.id }}" target="_blank">
                        <div class="d-flex px-2 py-1">
                          <div>
                            <soft-avatar
                              :img="'https://api.dicebear.com/7.x/bottts-neutral/svg?seed='+ currentSession.client_data.first_name "
                              size="sm"
                              border-radius="lg"
                              class="me-3"
                              alt="{{ currentSession.client_data.first_name + ' ' + currentSession.client_data.last_name }}"
                            />
                          </div>
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="mb-0 text-sm">{{ currentSession.client_data.first_name + ' ' + currentSession.client_data.last_name }}</h6>
                            <p class="text-xs text-secondary mb-0">
                              {{currentSession.client_data.email}}
                            </p>
                          </div>
                        </div>
                      </router-link>
                    </td>
                    <td>
                      <p class="text-sm text-success font-weight-bolder mb-0"> {{ currentSession.title }} </p>
                    </td>
                    <td>
                      <p class="text-xs text-secondary mb-0"> {{ currentSession.date_time.substring(0, 10) }} </p>
                      <p class="text-xs text-secondary mb-0">total sessions: {{ currentSession.client_sessions.length }}</p>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <a :href="currentSession.client_data.journey ? '/journeys/'+ currentSession.client_data.journey.id : 'javascript::'" target="_blank">
                      <soft-badge color="info" variant="gradient" size="sm"
                        >{{ currentSession.client_data.journey ? "assigned" : 'not assigned' }}</soft-badge
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Session-Phase Relation -->
    <div class="row mt-3" v-if="currentSession.client_data.journey">
      <div class="col-lg-6 mb-3">
        <div class="card">
          <div class="card-body">
            <label>Which phase of the journey are you going to focusing on?</label>
            <select v-model="currentSession.phase_data" @change="updateSessionPhase" class="form-select form-select-border-radius-3 text-sm text-bold">
              <option selected disabled :value="currentSession.phase_data ? currentSession.phase_data : ''">
                {{currentSession.phase_data ? currentSession.phase_data.name : 'None'}}
              </option>
              <option :value="null" v-if="currentSession.phase_data">None</option>
              <option v-for="phase in currentSession.client_data.journey.phases" :key="phase" :value="phase">
                <span class="text-sm">{{phase.name}}</span>
              </option>
            </select>
            <div class="mt-2" v-if="showPhaseMessage">
              <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
                {{message}}
              </soft-alert>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="currentSession.phase_data">
        <div class="card mb-4">
          <div class="card-body">
            <label>You can update your progress here</label>
            <!-- ToDo Card -->
            <TodoCard :todos="currentSession.phase_data.todos" @status-update="updateTodoStatus"/>
          </div>
        </div>
      </div>
    </div>

    <!-- Notes -->
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Notes</h6>
            <p class="text-sm"></p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <!-- TipTap Editor -->
              <h6>Therapistory Text Editor is at your disposal.</h6>
              <p class="text-sm">write what is important...</p>
              <div v-for="note in currentSession.notes" :key="note" class="col-lg-12">
                <TipTap :currentUser="currentSession.client_data" @content="saveContent" :note="note"/>
              </div>
              <div class="col-lg-4 mt-2" v-if="showNoteMessage">
                <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
                  {{message}}
                </soft-alert>
              </div>
            </div>
            <!-- <hr> -->
            <!-- <div class="row mt-3">
              <h6>The section below is showcasing our template based note taking features which are coming soon</h6>
              <div v-for="note in notes" :key="note" class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <NoteInfoCard
                :note="note"
                @remove-note="removeNote"/>
              </div>
              <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <NoteForm
                @new-note="addNote"/>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

     <!-- Files     -->
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Files</h6>
            <p class="text-sm"></p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <!-- Rendering received files -->
              <FileItem v-for="file in currentSession.session_files" :key="file.id" :file="file" @delete-file="deleteFile"/>
              <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <FileUploadCard
                  :title="{ text: 'New File', variant: 'h5' }"
                  url="javascript::"
                  @file-selected="uploadFile"
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Message Zone -->
    <div class="col-lg-4 mt-2" v-if="showMessage">
      <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
        {{message}}
      </soft-alert>
    </div>
    <div class="col-lg-4 mt-2" v-if="showAssignmentMessage">
      <soft-alert :color="messageType== 'success' ? 'success': 'danger'">
        {{message}}
      </soft-alert>
    </div>
    <!-- Assignment -->
    <div class="mt-4 row">
      <div class="col-12">
        <div class="mb-4 card">
          <div class="p-3 pb-0 card-header">
            <h6 class="mb-1">Assignments</h6>
            <p class="text-sm"></p>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div v-for="assignment in currentSession.session_assignments" :key="assignment" class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <AssignmentInfoCard
                :assignment="assignment"
                @remove-assignment="removeAssignment"
                @status="updateAssignment"/>
              </div>
              <div class="mt-4 mb-4 col-12 col-md-6 col-xl-4 mt-md-0">
                <AssignmentForm
                @new-assignment="addAssignment"/>
              </div>
            </div>
            <div class="col-lg-6 inline mt-5">
              <router-link :to="{ name: 'Sessions'}" class="btn btn-info m-1">
                Go to Sessions
              </router-link>
              <button type="button" class="btn bg-gradient-success m-1 text-capitalize" v-if="currentSession.session_assignments.length > 0" @click.prevent="popup = true">
                Send the Assignments to My Client <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- Popup  -->
  <Popup v-if="popup"
  title="Share the assignments"
  description="We will send an email to your client including all the assignments of this session. You will also be in CC. Do you want to proceed?"
  color="success"
  confirmButtonText="Send"
  @close="popup = false"
  @confirm="sendAssignments"
    />
  
</template>
  
<script>

import AssignmentForm from './components/AssignmentForm.vue';
import AssignmentInfoCard from './components/AssignmentInfoCard.vue';
import FileUploadCard from './components/FileUploadCard.vue';
import SoftAvatar from '@/components/SoftAvatar.vue';
import SoftBadge from '@/components/SoftBadge.vue';
import SoftAlert from '@/components/SoftAlert.vue';
import FileItem from './components/FileItem.vue';
import axios from 'axios';
import TipTap from './components/TipTap.vue';
import TodoCard from './components/TodoCard.vue'
import Popup from './components/Popup.vue'
import Spinner from './components/Preloader.vue';
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
  
export default {
name: "SessionView",
components: {
    AssignmentForm,
    AssignmentInfoCard,
    FileUploadCard,
    SoftAvatar,
    SoftBadge,
    SoftAlert,
    TipTap,
    Spinner,
    FileItem,
    TodoCard,
    Popup
},
data() {
    return {
    show: false,
    notes: [],
    therapist: [],
    currentSession: [],
    noteSaved: false,
    popup: false,
    showMessage: false,
    showNoteMessage: false,
    showAssignmentMessage: false,
    showPhaseMessage: false,
    message: '',
    messageType: '',
    };
},
async created() {
  await this.fetchData('/therapy/api/therapists/').then((therapist)=>{
      this.therapist = therapist[0];
    });
  await this.getSession();
  this.show= true;
  },

methods: {
  async fetchData(target) {
      let nextPage = target; // Replace this with your API endpoint
      let variable = [];

      try {
        while (nextPage) {
          const response = await axios.get(nextPage);
          const data = response.data;

          // Append results from current page to allInstances
          variable = variable.concat(data.results);

          // Update nextPage with the URL of the next page, if it exists
          nextPage = data.next;
        }

        // At this point, allInstances will contain all fetched instances
        return variable;
        
      } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
      }
    },
  async getSession(){
    try {
      const response= await axios.get(`/therapy/api/sessions/${this.$route.params.id}/`);
      this.currentSession = response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
        throw error;
    }

  },
  async updateSessionPhase(){
    let payload={
      "phase": this.currentSession.phase_data ? this.currentSession.phase_data.id : null,
    };
    try {
        await axios.patch(`/therapy/api/sessions/${this.currentSession.id}/`, payload);
        this.getSession();
        this.showPhaseMessage= true;
        this.message="Session's phase updated successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showPhaseMessage= false;
        }, 2000);
    } catch (error) {
        this.showPhaseMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showPhaseMessage= false;
        }, 2000);
    }
  },
  async updateTodoStatus(id, value){
      let payload={
        "completed": value,
      };
      try {
          await axios.patch(`/therapy/api/todos/${id}/`, payload);
          this.getSession();
          this.showPhaseMessage= true;
          this.message="Progress updated successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showPhaseMessage= false;
          }, 2000);
      } catch (error) {
          this.showPhaseMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showPhaseMessage= false;
          }, 2000);
      }
  },
  async deleteFile(fileId) {

      try {
        // Make a DELETE request to remove the file from the generalDocs API
        await axios.delete(`/therapy/api/client_files/${fileId}/`);
        
        await this.getSession();
        this.showMessage= true;
        this.message="File deleted successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      } catch (error) {
        this.showMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      }


  },
  async uploadFile(file, fileName) {
    const maxFileSize = 10 * 1024 * 1024; //10m
    if (file.size <= maxFileSize) {
      try {
        // Create a FormData object to send the file
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', fileName ? fileName : file.name);
        formData.append('therapist', this.currentSession.client_data.therapist);
        formData.append('client', this.currentSession.client_data.id);
        formData.append('session', this.currentSession.id);

        // Make a POST request to upload the file
        await axios.post('/therapy/api/client_files/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        await this.getSession();
        this.showMessage= true;
        this.message="File uploaded successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showMessage= false;
        }, 2000);
      } catch (error) {
        // Handle error
        this.showMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showMessage= false;
        }, 5000);
      }
    } else {
      // Handle error
      this.showMessage= true;
      this.message="Error: "+ "file size bigger than 10MB limit"
      this.messageType= 'danger'
      setTimeout(() => {
        this.showMessage= false;
      }, 2000);
    }

  },
  async saveContent(content){

    let payload= {
      "content": content,
    };
    try {
          await axios.patch(`therapy/api/notes/${this.currentSession.notes[0].id}/`, payload);
          this.getSession();
          this.showNoteMessage= true;
          this.message="Note saved successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showNoteMessage= false;
          }, 2000);

        } catch (error) {
          this.showNoteMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showNoteMessage= false;
          }, 2000);
        }
  },
  async removeAssignment(assignmentId) {
    try {
          // Make the delete request with the assignmentId
          await axios.delete(`/therapy/api/assignments/${assignmentId}/`);
          this.getSession();
          this.showAssignmentMessage= true;
          this.message="Assignment removed successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showAssignmentMessage= false;
          }, 2000);
      } catch (error) {
          this.showAssignmentMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showAssignmentMessage= false;
          }, 2000);
        }
  },
  async addAssignment(assignment){
    const newAssignment = {
      "status": false,
      "title": assignment.title,
      "description": assignment.description,
      "session": this.currentSession.id
    };
    try {
          await axios.post('/therapy/api/assignments/', newAssignment);
          this.getSession();
          this.showAssignmentMessage= true;
          this.message="Assignment added successfully."
          this.messageType= 'success'
          setTimeout(() => {
            this.showAssignmentMessage= false;
          }, 2000);
      } catch (error) {
        console.log(error);
          this.showAssignmentMessage= true;
          this.message="Error: "+ error.response.data
          this.messageType= 'danger'
          setTimeout(() => {
            this.showAssignmentMessage= false;
          }, 2000);
        }
  },
  async updateAssignment(id, value){
    let payload={
      "status": value,
    };
    try {
        await axios.patch(`/therapy/api/assignments/${id}/`, payload);
        this.getSession();
        this.showAssignmentMessage= true;
        this.message="Assignment updated successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showAssignmentMessage= false;
        }, 2000);
    } catch (error) {

        this.showAssignmentMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showAssignmentMessage= false;
        }, 2000);
    }
  },
  async sendAssignments(){
    this.popup=false;
    let payload = {
      "client_data": this.currentSession.client_data,
      "assignment_data": this.currentSession.session_assignments
    };
    try {
        await axios.post('/therapy/api/email_assignments/', payload);
        
        this.showAssignmentMessage= true;
        this.message="Assignment shared with client successfully."
        this.messageType= 'success'
        setTimeout(() => {
          this.showAssignmentMessage= false;
        }, 2000);
    } catch (error) {
        this.showAssignmentMessage= true;
        this.message="Error: "+ error.response.data
        this.messageType= 'danger'
        setTimeout(() => {
          this.showAssignmentMessage= false;
        }, 2000);
    }
  },
},
mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
beforeRouteEnter(to, from, next) {
  axios.get(`/therapy/api/sessions/${to.params.id}/`)
    .then(response => {
      // Check if the response status indicates permission-denied
      if (response.status === 403 || response.status === 404) {
        // Redirect to 404 page
        next({ name: 'NotFound' });
      } else {
        // Proceed to enter the route
        next();
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      // Redirect to 404 page
      next({ name: 'NotFound' });
    });
},
};
</script>

<style scoped>
.card {
flex: 0 0 auto;
}
</style>  